const Resources = [
    {
        "name": "Women's Center and Shelter of PGH",
        "phone_link": "tel:4126878005",
        "formatted_phone": "412-687-8005",
        "website": "https://www.wcspittsburgh.org/",
        "description": "Emergency shelter for women experiencing domestic violence and their children. Safe harbor that provides specialized care and support for survivors who have experienced all types of intimate partner violence from physical to emotional through Emergency Shelter, Legal Advocacy, a 24-Hour Hotline, Support Groups, and more. *This resource may not be suitable for men and trans or gender-non confirming individuals*",
        "tags": ["Immediate", "24/7", "Pittsburgh", "Crisis", "Violence", "Housing", "Families", "Youth", "Houseless"]
    },
    {
        "name": "Pittsburgh Action Aginst R*pe",
        "phone_link": "tel:18663637273",
        "formatted_phone": "1-866-363-7273",
        "website": "https://paar.net/about-paar/contact-location/",
        "description": "\"Pittsburgh Action Against R*pe is one of the oldest r*pe crisis centers in the country. PAAR has provided services to Allegheny County for more than 47 years. Experienced, trained counselors and advocates provide services that are completely confidential, and just a phone call away.\"",
        "tags": ["Immediate", "24/7", "Pittsburgh", "Crisis", "Violence"]
    },
    {
        "name": "Pittsburgh Union of Regional Renters",
        "phone_link": "tel:4124448497",
        "formatted_phone": "412-444-8497",
        "website": "https://pgh-urr.org/",
        "description": "Anti-Eviction Response Team. Housing is a human right. If your landlord is trying to take it call this number.",
        "tags": ["Immediate", "24/7", "Pittsburgh", "Housing"]
    },
    {
        "name": "Re;solve Crisis Services",
        "phone_link": "tel:18887968226",
        "formatted_phone": "1-888-796-8226",
        "website": "https://www.upmc.com/services/behavioral-health/resolve-crisis-services",
        "description": "For all residents of Allegheny County. \"Everyone experiences moments of crisis in life. You might be struggling with depression or substance abuse. Or maybe you’re overwhelmed by problems at work, feeling lonely, or having a conflict with a loved one. Whether your problem is large or small, talking with someone can help. Most of all, don’t wait for a problem to get out of control.\"",
        "tags": ["Immediate", "Pittsburgh", "24/7", "Crisis", "Mental Health", "Possible Police"]
    },
    {
        "name": "Community Human Services",
        "phone_link": "tel:4122461600",
        "formatted_phone": "412-246-1600",
        "website": "https://chscorp.org/",
        "description": "\"CHS empowers individuals and families to live in stable housing, connect to community resources, build relationships, and access quality food.\"",
        "tags": ["Referral", "Pittsburgh", "Housing", "Youth", "Harm Reduction"]
    },
    {
        "name": "24/7 Crisis Text Line",
        "phone_link": "sms:741741",
        "formatted_phone": "Text HOME to 741741",
        "website": "https://www.crisistextline.org/",
        "description": "Connect with a crisis counselor for free 24/7 support at your fingertips",
        "tags": ["Immediate", "24/7", "Crisis"]
    },
    {
        "name": "National Su/c/de Prevention Lifeline",
        "phone_link": "tel:18002738255",
        "formatted_phone": "1-800-273-8255",
        "website": "https://suicidepreventionlifeline.org/",
        "description": "\"The Lifeline provides 24/7, free and confidential support for people in distress, prevention and crisis resources for you or your loved ones, and best practices for professionals.\"",
        "tags": ["Immediate", "24/7", "Crisis", "Mental Health"]
    },
    {
        "name": "R*pe Abust Incest National Network",
        "phone_link": "tel:8006564673",
        "formatted_phone": "800-656-4673",
        "website": "https://www.rainn.org/about-national-sexual-assault-telephone-hotline",
        "description": "Call to be connected with a trained staff member from a sexual assault service provider in your area.",
        "tags": ["Immediate", "24/7", "Crisis", "Violence"]
    },
    {
        "name": "United Way Helpline",
        "phone_link": "tel:211",
        "formatted_phone": "211",
        "website": "https://uwswpa.org/call-2-1-1/",
        "description": "Highly trained 2‑1‑1 call specialists provide referrals to basic human needs resources, physical and mental health resources, employment support, support for older adults and persons with disabilites, youth and child care program, and regional disaster preparation",
        "tags": ["Immediate", "24/7", "Anyone", "Youth", "Elders"]
    },
    {
        "name": "Substance Abuse and Mental Health Services Administration Helpline",
        "phone_link": "tel:18006624357",
        "formatted_phone": "1-800-662-4357",
        "website": "https://www.samhsa.gov/find-help/national-helpline",
        "description": "\"Confidential, free, 24-hour-a-day, 365-day-a-year, information service, in English and Spanish, for individuals and family members facing mental and/or substance use disorders. This service provides referrals to local treatment facilities, support groups, and community-based organizations. Callers can also order free publications and other information.\"",
        "tags": ["Immediate", "Harm Reduction", "Mental Health"]
    },
    {
        "name": "Trans Lifeline",
        "phone_link": "tel:8775658860",
        "formatted_phone": "877-565-8860",
        "website": "https://translifeline.org/",
        "description": "\"Trans Lifeline is a trans-led organization that connects trans people to the community, support, and resources they need to survive and thrive.\"",
        "tags": ["Immediate", "Crisis", "LGBTQ+"]
    },
    {
        "name": "The Trevor Project",
        "phone_link": "tel:18664887386",
        "formatted_phone": "1-866-488-7386",
        "website": "https://www.thetrevorproject.org/",
        "description": "You can also text START to 678678. \"The Trevor Project is the leading national organization providing crisis intervention and suicide prevention services to lesbian, gay, bisexual, transgender, queer & questioning (LGBTQ) young people under 25.\"",
        "tags": ["Immediate", "24/7", "Crisis", "LGBTQ+"]
    },
    {
        "name": "National Alliance On Mental Illness",
        "phone_link": "tel:8009506264",
        "formatted_phone": "800-950-6264",
        "website": "https://www.nami.org/Home",
        "description": "\"The nation's largest grassroots mental health organization dedicated to building better lives for the millions of Americans affected by mental illness.\" Available 10AM-6PM",
        "tags": ["Limited Hours", "Referral", "Mental Health"]
    },
    {
        "name": "Allegheny County Peer Support Warmline",
        "phone_link": "tel:8666619276",
        "formatted_phone": "866-661-9276",
        "website": "http://www.peer-support.org/?page_id=22",
        "description": "Trans lifeline is the only service in the country with a policy against non-consensual active rescue! \"The Warmline is staffed by mental health consumers who provide support to peers by telephone. All phone calls received are kept confidential and callers remain anonymous. Telephone Support Specialists are trained to actively listen to their peers, empathize with their concerns and empower individuals to choose their path to wellness and recovery.\"",
        "tags": ["Limited Hours", "Pittsburgh", "Mental Health"]
    },
    {
        "name": "BigBurgh.com",
        "phone_link": "",
        "formatted_phone": "",
        "website": "https://www.bigburgh.com/",
        "description": "\"Mobile-optimized web site that pulls together services that can help the houseless or those at risk of becoming houseless in the city of Pittsburgh. Included are only services that are free, good, and welcoming to those without an address.\"",
        "tags": ["Referral", "Pittsburgh", "Houseless", "Anyone"]
    },
    {
        "name": "Allegheny Link",
        "phone_link": "tel:8667302368",
        "formatted_phone": "866-730-2368",
        "website": "https://www.alleghenycounty.us/human-services/about/contact/allegheny-link.aspx",
        "description": "Connecting people who: have a disability and/or are over the age of 60, and/or who are experiencing or at risk of houselessness, and/or are pregnant, and/or are parents/caregivers of children to services.",
        "tags": ["Referral", "Pittsburgh", "Anyone", "Families", "Youth", "Elders", "Houseless", "Mental Health"]
    },
    {
        "name": "Prevention Point PGH",
        "phone_link": "tel:4122473404",
        "formatted_phone": "412-247-3404",
        "website": "https://www.pppgh.org/",
        "description": "Harm Reduction. Safer injection supplies, case management services, education on HIV and Hep C prevention, overdose prevention and response training, naloxone distribution, testing, assistance to recovery treatment, health care, housing, food, etc.",
        "tags": ["Immediate", "Pittsburgh", "Harm Reduction", "Medical", "Housing", "Houseless", "Referral"]
    },
    {
        "name": "Central Outreach",
        "phone_link": "sms:4123224151",
        "formatted_phone": "Text 412-322-4151",
        "website": "https://www.centraloutreach.com/",
        "description": "LGBTQIA competent healthcare. Primary Care, STI, HIV, HEP testing, HRT access and support. Harm reduction competent, rehab and recovery services. Pharmacy discounts.",
        "tags": ["Referral", "Pittsburgh", "Anyone", "Harm Reduction", "LGBTQ+", "Houseless", "Medical"]
    },
    {
        "name": "Pittsburgh Mutual Aid",
        "phone_link": "tel:4123016127",
        "formatted_phone": "Call or text 412-301-6127",
        "website": "https://www.pittsburghmutualaid.com/",
        "description": "You can get or give help with buying groceries/food, running errands, financial assistance, finding housing, and more. They have a portal on their website of resources to check out. You can get involved.",
        "tags": ["Referral", "Pittsburgh", "Anyone"]
    }
];

export default Resources;